// import CryptoJS from 'crypto-js';  
var CryptoJS = require("crypto-js");

// Encryption key (load it from an environment variable or .env file)
// const encryptionKey = process.env.VUE_APP_ENCRYPTION_KEY;
const encryptionKey = '8db432345be69470574ec7a2b3f05d9a6791c307533435c2bf7244c305426032';

function generateMAC(data) {
  const hmac = CryptoJS.HmacSHA256(data, encryptionKey);
  return hmac.toString();
}

function encryptData(data) {
  const mac = generateMAC(data);
  const encryptedData = CryptoJS.AES.encrypt(`${data}:${mac}`, encryptionKey).toString();
  return encryptedData;
}

// function verifyMAC(encryptedData) {
//   const decryptedData = CryptoJS.AES.decrypt(encryptedData, encryptionKey).toString(CryptoJS.enc.Utf8);
//   const [data, mac] = decryptedData.split(':');
//   const computedMAC = generateMAC(data);
//   return mac === computedMAC;
// }

function decryptData(encryptedData) {
  // if (verifyMAC(encryptedData)) {
  const decryptedData = CryptoJS.AES.decrypt(encryptedData, encryptionKey).toString(CryptoJS.enc.Utf8);
  return decryptedData;
  // }
  // return null; // Return null or handle tampered data as needed
}

export {
  encryptData,
  decryptData,
};
