<template>
  <div class="join-session"> 
    <div class="session-ended" v-if="isSessionExpired">
      <img src="@/assets/logo.svg" alt="telein-logo">
      <h1>Tele-Session Has Ended</h1>
      <div>
        <a :href="failureRedirectionUrl">Go Back</a>
      </div>
    </div>    
    <div v-else>
      <dyte-meeting id="my-meeting" show-setup-screen="true" />
    </div>
  </div>
</template>

<script>
import { decryptData } from '@/packages/crypto/crypto.js';
import moment from 'moment';
import 'moment-timezone';

export default {
  props: {
    authToken: String, // Assuming authToken is a string
    roomName: String // Assuming roomName is a string
  },
  data() {
    return {
      currentDateTime: moment.tz('Asia/Riyadh').format('YYYY-MM-DD HH:mm:ss'),
      failureRedirectionUrl: process.env.FAILURE_REDIRECT_URL
      // isSessionExpired: false
    };
  },
  computed: {
    sessionEndTime() {
      let started_date = null;
      let duration = null;
      const sd = localStorage.getItem('sd');
      if (sd) {
        started_date = decryptData(sd);
        // Find the index of the last ":"
        const lastIndex = started_date.lastIndexOf(':');
        // Split the string using the last ":"
        const dateAndTime = started_date.substring(0, lastIndex);
        started_date = dateAndTime;
      }
      const drn = localStorage.getItem('drn');
      if (drn) {
        duration = decryptData(drn);
        // Find the index of the last ":"
        const lastIndex = duration.lastIndexOf(':');
        // Split the string using the last ":"
        const time = duration.substring(0, lastIndex);
        duration = time;
      }
      const startedDate = moment.tz(started_date, 'Asia/Riyadh'); // Use the KSA time zone
      const endTime = startedDate.clone().add(duration, 'minutes');
      // return endTime.format('YYYY-MM-DD HH:mm:ss');
      // Check if the end time is valid
      if (endTime.isValid()) {
        // If the end time is valid, return it in the desired format
        return endTime.format('YYYY-MM-DD HH:mm:ss');
      } else {
        // If the end time is not valid, return a value less than the current time to indicate an expired session
        return this.currentDateTime;
      }
    },
    isSessionExpired() {
      return false;
      //return this.currentDateTime >= this.sessionEndTime;
    }
  },
  methods: {
    formatDateTime() {
      // Create a new DateTimeFormat object with the desired time zone
      const formatter = new Intl.DateTimeFormat('en-US', {
        timeZone: this.targetTimeZone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });

      // Get the current date and time in the specified time zone
      const currentDate = new Date();
      return formatter.format(currentDate);
    },
    redirectUser() {
      window.location.href = this.failureRedirectionUrl;
    }
  },
  created() {
    setInterval(() => {
      this.currentDateTime = moment
        .tz('Asia/Riyadh')
        .format('YYYY-MM-DD HH:mm:ss');
    }, 1 * 1000);
  },

  mounted() {
    if (!this.authToken) {
      alert("An authToken wasn't passed, please try again to join a meeting.");
      this.redirectUser();
    }
    if (this.authToken && !this.isSessionExpired) {
      window.DyteClient.init({
        authToken: this.authToken,
        roomName: this.roomName
      }).then(meeting => {
        document.getElementById('my-meeting').meeting = meeting;
        meeting.self.on('roomLeft', ({ state }) => {
          if (state === 'left' || state === 'ended') {
            setTimeout(this.redirectUser(), 2 * 1000);
          }
        });
      });
    }
  }

  // mounted() {
  //   // ... Your existing code

  //   if (this.authToken && !this.isSessionExpired) {
  //     window.DyteClient.init({
  //       authToken: this.authToken,
  //       roomName: this.roomName
  //     }).then(meeting => {
  //       const myMeetingElement = document.getElementById('my-meeting');
  //       myMeetingElement.meeting = meeting;

  //       meeting.participants.joined.on('participantLeft', participant => {
  //         console.log(
  //           `A participant with id "${participant.id}" has left the meeting`
  //         );
  //       });
  //     });
  //   }
  // }

  // mounted() {
  //   const searchParams = new URL(window.location.href).searchParams;
  //   const authToken = searchParams.get('authToken');
  //   const roomName = searchParams.get('roomName') || '';
  //   if (!authToken) {
  //     alert(
  //       "An authToken wasn't passed, please pass an authToken in the URL query to join a meeting."
  //     );
  //   }
  //   window.DyteClient.init({
  //     authToken,
  //     roomName
  //   }).then(meeting => {
  //     document.getElementById('my-meeting').meeting = meeting;
  //   });
  // },
};
</script>
<style lang="scss" scoped>
.session-ended {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: #3f4aaa;
  color: #3f4aaa;
  text-align: center;
  overflow: hidden;
  background-color: #f0f0f0; /* Background color while loading */
}
</style>


mounted() {
  if (!this.authToken) {
    alert("An authToken wasn't passed, please try again to join a meeting.");
    this.redirectUser();
  }
  if (this.authToken && !this.isSessionExpired) {
    window.DyteClient.init({
      authToken: this.authToken,
      roomName: this.roomName
    }).then(meeting => {
      const myMeetingElement = document.getElementById('my-meeting');
      myMeetingElement.meeting = meeting;

      // Add event listeners for leave and end session
      meeting.on('ended', () => {
        this.leaveMeeting();
      });

      meeting.on('left', () => {
        this.leaveMeeting();
      });
    });
  }
},
