<template>
  <router-view />
</template>
<script>
export default {};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}
a {
  font-weight: bold;
  color: #2c3e50;
  display: block;
  &.router-link-exact-active {
    color: #42b983;
  }
}
</style>
