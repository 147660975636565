<template>
  <div class="session-check">
    <div class="loading">
      <img src="@/assets/logo.svg" alt="telein-logo">
      <h1>Checking Your Credentials...</h1>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { encryptData } from '@/packages/crypto/crypto.js';
export default {
  data() {
    return {
      failureRedirectionUrl: process.env.FAILURE_REDIRECT_URL
    };
  },
  computed: {
    ...mapGetters('dyte/session', [
      'checkLoading',
      'checkResponse',
      'checkError'
    ]),
    sessionKey() {
      return this.$route.query.session_key || '';
    }
  },
  created() {
    if (!this.sessionKey) {
      this.redirectUser();
    }
    this.checkKey();
  },
  methods: {
    ...mapActions('dyte/session', ['check']),
    checkKey() {
      this.check(this.sessionKey)
        .then(res => {
          // console.log(res);
          const authToken =
            res.data.data.session_url.match(/authToken=(.+)/)[1] || '';
          const { started_date, duration } = res.data.data;
          const { status } = res.data.metaData;
          if (status == 200 && authToken) {
            const drn = encryptData(duration);
            localStorage.setItem('drn', drn);
            const sd = encryptData(started_date);
            localStorage.setItem('sd', sd);
            console.log('Success');
            this.$router.push({
              name: 'JoinSession',
              query: { authToken: authToken }
            });
          }
        })
        .catch(err => {
          // console.log(err);
          const { status } = err.data.metaData;
          if (status == 403 || status == 404) {
            const failure_redirection_url =
              err.data.data.failure_redirection_url ||
              this.failureRedirectionUrl;
            console.log('Fail');
            this.failureRedirectionUrl = failure_redirection_url;
            this.redirectUser();
          }
        });
    },
    redirectUser() {
      window.location.href = this.failureRedirectionUrl;
    }
  }
};
</script>
<style lang="scss" scoped>
.session-check {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: #3f4aaa;
  color: #3f4aaa;
  text-align: center;
  overflow: hidden;
  background-color: #f0f0f0; /* Background color while loading */
}
</style>