import { createStore } from "vuex";
import dyte from './dyte/dyte'
export default createStore({
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    dyte
  },
});
