// import { createRouter, createWebHashHistory } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import JoinSession from '@/components/dyte/JoinSession';
import SessionCheck from '@/components/dyte/SessionCheck';
const routes = [
  {
    path: '/',
    name: 'SessionCheck',
    component: SessionCheck,
    props: true,
  },
  {
    path: '/join-session',
    name: 'JoinSession',
    component: JoinSession,
    props: (route) => ({
      authToken: route.query.authToken,
      roomName: route.query.roomName
    }),
  },
];

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes,
});

export default router;
